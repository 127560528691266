import { ZipSuggestion } from '@wla/app/api/get-zipcode-suggestions/route';
import { Checkbox } from '@wla/components/ui/forms/checkbox';
import { EmailInput } from '@wla/components/ui/forms/email-input';
import { FormGroup, InputTypes } from '@wla/components/ui/forms/form-group';
import { Input } from '@wla/components/ui/forms/input';
import { ZipToCityInput } from '@wla/components/ui/forms/zip-to-city-input';
import { TranslationValues, useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import {
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
  UseFormSetError,
  UseFormTrigger,
} from 'react-hook-form';

type FormField<T extends Record<string, unknown>> = {
  className?: string;
  label: string;
  name: keyof T | string;
  condition?: (data: T & Record<string, unknown>) => boolean | undefined;
  input: InputTypes | null;
  required?: boolean | ((data: T & Record<string, unknown>) => boolean);
  readOnly?: boolean | ((data: T & Record<string, unknown>) => boolean);
  autocomplete?: HTMLInputElement['autocomplete'];
  prefix?: string;
};

export type FormSchema<T extends Record<string, unknown>> = FormField<T>[][];

export function getDefaultValuesForFields<T extends Record<string, unknown>>(
  fieldslist: FormSchema<T>,
  exludeFields?: (keyof T)[],
) {
  const defaultValues: T = {} as T;
  fieldslist.forEach((fields) => {
    fields.forEach((field) => {
      if (!field.name || (exludeFields && exludeFields.includes(field.name as keyof T))) return;

      if (field.input === InputTypes.Headline) return;

      if (
        field.input === InputTypes.Input ||
        field.input === InputTypes.Email ||
        field.input === InputTypes.ZipToCity
      ) {
        // @ts-expect-error should be a string
        defaultValues[field.name as keyof T] = '';
      }
    });
  });

  return defaultValues;
}

function getNestedError(
  errors: FieldErrors<Record<string, unknown>>,
  prefix: string,
): FieldErrors<Record<string, unknown>> | undefined {
  const keys = prefix.split('.').filter((key) => key !== '');

  let current = errors;
  for (const key of keys) {
    if (!current) {
      return undefined;
    }
    const arrayIndexMatch = key.match(/(\w+)\[(\d+)\]/);
    if (arrayIndexMatch) {
      const arrayKey = arrayIndexMatch[1];
      const index = parseInt(arrayIndexMatch[2], 10);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore We are checking here that it actually is an array and it exists
      if (!current[arrayKey] || !Array.isArray(current[arrayKey]) || current[arrayKey].length <= index) {
        return undefined;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore We know it contains an index from the arrayIndexMatch regexp
      current = current?.[arrayKey]?.[index];
    } else {
      // @ts-expect-error Typescript can't handle the splitting and concatting of the keys
      current = current?.[key];
    }
  }

  return current;
}

function getFieldFromFormFields<T extends Record<string, unknown>>(fields: FormSchema<T>, inputType: InputTypes) {
  const fieldsWithInput = fields.find((fields) => fields.find((field) => field.input === inputType));
  if (!fieldsWithInput) {
    return null;
  }
  return fieldsWithInput.find((field) => field.input === inputType);
}

export const knownEmails = [{}];

export function FormFieldsGenerator<T extends Record<string, unknown>>({
  prefixName = '',
  fieldslist,
  removeFields = [],
  autocompleteAddition,
  form,
  externalProps = {},
}: {
  prefixName?: string;
  fieldslist: FormSchema<T>;
  removeFields?: (keyof T)[];
  autocompleteAddition?: 'billing' | 'shipping';
  form: UseFormReturn<T>;
  externalProps?: Record<string, unknown>;
}) {
  const t = useTranslations();
  // const { defaultLocale } = usePublicConfig();
  const [lockCity, setLockCity] = useState(false);

  const {
    watch,
    register,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = form;

  const values = watch();

  // Make sure the city field is filled out if the zip code is filled out and
  // the city field is not locked on initial render
  useEffect(() => {
    async function fetchAndSetCity(zipCode: string, cityFieldName: Path<T>, zipCodeFieldName: Path<T>) {
      const resp = await fetch('/api/get-zipcode-suggestions', {
        method: 'POST',
        body: JSON.stringify({ zipCode }),
      });
      const data = (await resp.json()) as ZipSuggestion;
      if (data.success && data.city) {
        setValue(cityFieldName, data.city as PathValue<T, Path<T>>);
        setLockCity(true);
      } else {
        setError(zipCodeFieldName, { message: 'forms.errors.not-valid.post-code-not-valid' });
      }
    }

    const initValues = getValues();

    // Check if form contains a zip code and city field
    const zipCodeField = getFieldFromFormFields(fieldslist, InputTypes.ZipToCity);
    const cityField = getFieldFromFormFields(fieldslist, InputTypes.CityFromZip);

    if (!zipCodeField || !cityField) return;

    const zipCodeFieldName = `${prefixName}${zipCodeField.name as string}` as Path<T>;
    const cityFieldName = `${prefixName}${cityField.name as string}` as Path<T>;

    const zipCodeValue = initValues[zipCodeFieldName];
    const cityValue = initValues[cityFieldName];

    if (zipCodeValue && cityValue) {
      setLockCity(true);
    }
    if (zipCodeValue && !cityValue) {
      fetchAndSetCity(zipCodeValue as string, cityFieldName, zipCodeFieldName);
    }
  }, []);

  return (
    <div className="flex w-full flex-col gap-3 lg:gap-4">
      {fieldslist.map((fields, rowIndex) => {
        // Return null if the condition is false or if the row only contains fields that should be removed
        if (
          fields.filter(
            (field) => field.condition && field.condition({ ...externalProps, ...values, prefixName }) === false,
          ).length === fields.length ||
          fields.filter((field) => field.name && removeFields.includes(field.name as keyof T)).length === fields.length
        ) {
          return null;
        }

        return (
          <div key={`row_${rowIndex}`} className="flex w-full flex-col gap-3 md:flex-row md:gap-2.5" data-type="row">
            {fields.map((field) => {
              const fieldName = `${prefixName}${field.name as string}` as Path<T>;

              const errorsWithPrefix = getNestedError(errors, prefixName);

              if (field.name && removeFields.includes(field.name)) {
                return null;
              }

              if (field.condition && field.condition({ ...externalProps, ...values, prefixName }) === false) {
                return null;
              }

              const key = `row_${rowIndex}_${fieldName}`;

              const required =
                typeof field.required === 'function'
                  ? field.required({ ...externalProps, ...values, prefixName })
                  : field.required;
              const readOnly =
                typeof field.readOnly === 'function'
                  ? field.readOnly({ ...externalProps, ...values, prefixName })
                  : field.readOnly;

              let errorMessage = '';
              // Check if the error message is a custom error message for must be XX digits
              if (errorsWithPrefix?.[field.name as string]?.message?.startsWith('custom.digits.')) {
                const number = errorsWithPrefix?.[field.name as string]?.message?.split('.')[2];
                errorMessage = t('forms.errors.must-be-digits', { number });
              }
              // Check if the error message is a custom error message for maximum characters
              else if (errorsWithPrefix?.[field.name as string]?.message?.startsWith('custom.max.')) {
                const number = errorsWithPrefix?.[field.name as string]?.message?.split('.')[2];
                errorMessage = t('forms.errors.max-characters', { number });
              } else if (errorsWithPrefix?.[field.name as string]?.message) {
                errorMessage = t(errorsWithPrefix?.[field.name as string]?.message, values as TranslationValues);
              }

              const formGroupProps = {
                label: t(field.label),
                inputType: field.input as InputTypes,
                required,
                className: field.className || 'flex-1',
                validationError: errorMessage,
              };

              switch (field.input) {
                case InputTypes.Headline:
                  return (
                    <h3 key={`row_${rowIndex}_${fieldName}`} className={field.className || 'text-sm text-gray-500'}>
                      {t(field.label)}
                    </h3>
                  );

                case InputTypes.Checkbox:
                  return (
                    <FormGroup key={key} {...formGroupProps}>
                      <Checkbox {...register(fieldName)} />
                    </FormGroup>
                  );
                case InputTypes.PrefixInput:
                  return (
                    <FormGroup key={key} {...formGroupProps} disabled={readOnly} className="relative w-full">
                      <Input
                        {...register(fieldName)}
                        readOnly={readOnly}
                        disabled={readOnly}
                        autoComplete={
                          field.autocomplete && [autocompleteAddition, field.autocomplete].filter(Boolean).join(' ')
                        }
                        // needed to use specific pixels here
                        className="pl-[72px]"
                      />
                      <span className="absolute left-0 top-0 h-full max-h-14 rounded rounded-r-none border border-gray-400 bg-gray-200 p-4 before:absolute">
                        {field.prefix}
                      </span>
                    </FormGroup>
                  );
                case InputTypes.ZipToCity: {
                  const zipCodeField = getFieldFromFormFields(fieldslist, InputTypes.ZipToCity);
                  if (!zipCodeField) return;
                  const zipCodeFieldName = `${prefixName}${zipCodeField.name as string}` as Path<T>;

                  return (
                    <FormGroup key={key} {...formGroupProps} disabled={readOnly}>
                      <ZipToCityInput
                        {...register(fieldName)}
                        readOnly={readOnly}
                        disabled={readOnly}
                        trigger={form.trigger as UseFormTrigger<FieldValues>}
                        setError={setError as UseFormSetError<FieldValues>}
                        zipCodeFieldName={zipCodeFieldName}
                        onZipCodeChange={({ city }) => {
                          // If the city is empty, unlock the city field
                          const cityField = getFieldFromFormFields(fieldslist, InputTypes.CityFromZip);
                          if (!cityField) return;

                          const cityFieldName = `${prefixName}${cityField.name as string}` as Path<T>;

                          if (!city) {
                            setLockCity(false);
                          } else {
                            form.setValue(cityFieldName, city as PathValue<T, Path<T>>);
                            form.trigger([cityFieldName as Path<T>, zipCodeFieldName as Path<T>]);
                            setLockCity(true);
                          }
                        }}
                        autoComplete={
                          field.autocomplete && [autocompleteAddition, field.autocomplete].filter(Boolean).join(' ')
                        }
                      />
                    </FormGroup>
                  );
                }
                case InputTypes.CityFromZip:
                  return (
                    <FormGroup key={key} {...formGroupProps} disabled={readOnly}>
                      <Input
                        {...register(fieldName)}
                        readOnly={readOnly || lockCity}
                        disabled={readOnly}
                        autoComplete={
                          field.autocomplete && [autocompleteAddition, field.autocomplete].filter(Boolean).join(' ')
                        }
                      />
                    </FormGroup>
                  );
                case InputTypes.Email:
                  return (
                    <FormGroup key={key} {...formGroupProps} disabled={readOnly}>
                      <EmailInput
                        {...register(fieldName)}
                        type="email"
                        readOnly={readOnly}
                        disabled={readOnly}
                        autoComplete={
                          field.autocomplete && [autocompleteAddition, field.autocomplete].filter(Boolean).join(' ')
                        }
                      />
                    </FormGroup>
                  );

                case InputTypes.Input:
                  return (
                    <FormGroup key={key} {...formGroupProps} disabled={readOnly}>
                      <Input
                        {...register(fieldName)}
                        readOnly={readOnly}
                        disabled={readOnly}
                        autoComplete={
                          field.autocomplete && [autocompleteAddition, field.autocomplete].filter(Boolean).join(' ')
                        }
                      />
                    </FormGroup>
                  );
              }
            })}
          </div>
        );
      })}
    </div>
  );
}
