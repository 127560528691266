import { ZipSuggestion } from '@wla/app/api/get-zipcode-suggestions/route';
import { Input } from '@wla/components/ui/forms/input';
import debounce from 'lodash/debounce';
import { ComponentPropsWithoutRef, forwardRef, useCallback, useRef } from 'react';
import { FieldValues, Path, UseFormSetError, UseFormTrigger } from 'react-hook-form';

type ZipToCityInputInputProps<T extends FieldValues> = ComponentPropsWithoutRef<'input'> & {
  onZipCodeChange: (values: { zipCode: string; city: string }) => void;
  trigger: UseFormTrigger<T>;
  setError: UseFormSetError<T>;
  zipCodeFieldName: Path<T>;
};

export const ZipToCityInput = forwardRef(function ZipToCityInput<T extends FieldValues>(
  { onChange, onZipCodeChange, trigger, setError, zipCodeFieldName, ...rest }: ZipToCityInputInputProps<T>,
  ref: React.Ref<HTMLInputElement>,
) {
  const abortControllerRef = useRef<AbortController | null>(null);

  async function getZipCodeSuggestions(zipCode: string) {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    try {
      const resp = await fetch('/api/get-zipcode-suggestions', {
        method: 'POST',
        body: JSON.stringify({ zipCode }),
        signal,
      });
      const data = (await resp.json()) as ZipSuggestion;
      return data;
    } catch {
      return { success: false, city: '' };
    } finally {
      abortControllerRef.current = null;
    }
  }

  const debouncedGetZipCodeSuggestions = useCallback(
    debounce(async (zipCode, onZipCodeChange, trigger, setError) => {
      const data = await getZipCodeSuggestions(zipCode);
      onZipCodeChange({ zipCode, city: data.success && data.city ? data.city : '' });
      await trigger(zipCodeFieldName);

      if (!data.success) {
        setError(zipCodeFieldName, { message: 'forms.errors.not-valid.post-code-not-valid' });
      }
    }, 300),
    [],
  );

  return (
    <Input
      onBlur={async (e) => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        const zipCode = e.target.value;
        if (zipCode) {
          const data = await getZipCodeSuggestions(zipCode);
          if (data.success && data.city) {
            onZipCodeChange({ zipCode, city: data.city });
          } else {
            setError(zipCodeFieldName as Path<T>, { message: 'forms.errors.not-valid.post-code-not-valid' });
          }
        }
      }}
      onChange={(e) => {
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        const zipCode = e.target.value;
        debouncedGetZipCodeSuggestions(zipCode, onZipCodeChange, trigger, setError);
        onChange?.(e);
      }}
      {...rest}
      ref={ref}
    />
  );
});
