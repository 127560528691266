import { useFormStatus } from 'react-dom';
import { Button, ButtonProps } from '../button/button';
import { Spinner } from '../spinner';

export function SubmitButton({
  children,
  loadingMessage,
  loading,
  ...props
}: Omit<ButtonProps, 'type'> & {
  loadingMessage?: string;
  loading?: boolean;
}) {
  const { pending } = useFormStatus();

  return (
    <Button {...props} type="submit" disabled={pending || loading || props.disabled}>
      {pending || loading ? (
        <span className="flex items-center gap-2">
          <Spinner size={props.size === 'medium' ? 'sm' : 'md'} />
          <span>{loadingMessage}</span>
        </span>
      ) : (
        children
      )}
    </Button>
  );
}
