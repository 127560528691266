import * as z from 'zod';

export const CatalogEnum = z.enum(['PC_COM_DK', 'PC_COM_PT']);
export const CountryISOCodeEnum = z.enum(['DK', 'PT']);
export const TLDEnum = z.enum(['dk', 'pt']);
export const LanguageISOCodeEnum = z.enum(['DA', 'PT']);
export const LocaleEnum = z.enum(['da-DK', 'pt-PT']);
export const CurrencyEnum = z.enum(['DKK', 'EUR']);
export const FooterBadgesEnum = z.enum(['emaerket', 'smiley']);
export const PaymentMethodsEnum = z.enum(['dankort', 'visa', 'mobilepay', 'mastercard', 'maestro', 'diners']);
export const NewsletterSubscriptionMethod = z.enum(['CONFIRMED_OPT_IN', 'DOUBLE_OPT_IN', 'OPT_IN']);
export const CheckoutSchema = z.object({
  postalCodeRegex: z.string(),
});

export const GoogleMapsSchema = z.object({
  key: z.string(),
});
export const TrustPilot = z
  .object({
    businessUnitId: z.string(),
    templateId: z.string(),
  })
  .optional();

export const GoogleTagManagerSchema = z.object({
  containerId: z.string(),
});

export const CookieConsent = z.object({
  cookieConsentId: z.string(),
});

export const CookieInformationSchema = z.object({
  bannerId: z.string(),
});

const ProductISOMappingSchema = z.record(
  z.object({
    url: z.string().url(),
  }),
);

export const DynamicYieldSchema = z.object({
  isEnabled: z.boolean(),
  id: z.number(),
});

export const SocialMediaSchema = z.object({
  facebookUrl: z.string().optional(),
  instagramUrl: z.string().optional(),
  linkedinUrl: z.string().optional(),
  youtubeUrl: z.string().optional(),
});

export const NewsletterSchema = z.object({
  b2cGroupId: z.number(),
  b2bGroupId: z.number(),
  eventId: z.number(),
  b2cSubscriptionMode: NewsletterSubscriptionMethod,
  b2bSubscriptionMode: NewsletterSubscriptionMethod,
});

export const FeatureFlagSchema = z.object({
  enableFileUploadOnFAQ: z.boolean().default(false),
  enableChat: z.boolean().default(false),
  showStockStatusOnProductCards: z.boolean().default(false),
  showStoreStock: z.boolean().default(false),
  showOnlineStock: z.boolean().default(false),
  showNextATPDate: z.boolean().default(false),
  enableOnlineSales: z.boolean().default(false),
  enableClickCollect: z.boolean().default(false),
  enableProductCustomerReviews: z.boolean().default(false),
  showDeliveryPriceTermsOnProducts: z.boolean().default(false),
  enableB2BCustomerCreation: z.boolean().default(false),
  enableB2BCustomerLoginAndCheckout: z.boolean().default(false),
  enableGiftcard: z.boolean().default(false),
  enableGiftcardRedeem: z.boolean().default(false),
  enableCouponRedeem: z.boolean().default(false),
  enableCustomerAddressAutocompleteZipCode: z.boolean().default(false),
  enableNewsletter: z.boolean().default(false),
  enableOptionalFirstnameNewsletter: z.boolean().default(false),
  showMandatoryTermsCheckboxNewsletter: z.boolean().default(false),
  showAgreementCheckboxNewsletter: z.boolean().default(false),
  enableBlackFridayTheme: z.boolean().default(false),
});

export const ConfigSchema = z.object({
  catalog: CatalogEnum.default('PC_COM_DK'),
  countryISOCode: CountryISOCodeEnum.default('DK'),
  languageISOCode: LanguageISOCodeEnum.default('DA'),
  defaultLocale: LocaleEnum.default('da-DK'),
  salesOrganisation: z.number().default(0),
  dynamicYield: DynamicYieldSchema.default({ isEnabled: true, id: 0 }),
  notFoundPath: z.string().default(''),
  recaptchaKey: z.string().default(''),
  currency: CurrencyEnum.default('EUR'),
  googleMaps: GoogleMapsSchema.default({ key: '' }),
  googleTagManager: GoogleTagManagerSchema.default({ containerId: '' }),
  cookieInformation: CookieInformationSchema.default({ bannerId: '' }),
  googleConsentModeEnabled: z.boolean().default(false),
  productSMOSMapping: ProductISOMappingSchema.default({}),
  trustPilot: TrustPilot,
  cookieConsent: CookieConsent.default({ cookieConsentId: '' }),
  footerBadges: z.array(FooterBadgesEnum).default([]),
  paymentIcons: z.array(PaymentMethodsEnum).default([]),
  b2bCaption: z.string().default(''),
  phoneNumber: z.string().default(''),
  socialMedia: SocialMediaSchema.default({ facebookUrl: '', instagramUrl: '', youtubeUrl: '', linkedinUrl: '' }),
  featureFlags: FeatureFlagSchema.default({}),
  jyskDomain: TLDEnum.default('dk'),
  publicHostname: z.string().default(''),
  newsletter: NewsletterSchema.default({
    b2cGroupId: 0,
    b2bGroupId: 0,
    eventId: 0,
    b2cSubscriptionMode: 'OPT_IN',
    b2bSubscriptionMode: 'OPT_IN',
  }),
  imageBaseUrl: z.string().default(''),
});

export type ConfigType = z.infer<typeof ConfigSchema>;
