import { FieldGroup } from '@wla/components/ui/forms/field-group';
import { FileUpload } from '@wla/components/ui/forms/file-upload';
import { Input } from '@wla/components/ui/forms/input';
import { Select } from '@wla/components/ui/forms/select';
import { TextArea } from '@wla/components/ui/forms/textArea';
import { cn } from '@wla/lib/helpers/cn';
import { ComponentPropsWithoutRef, ComponentType, cloneElement, isValidElement, useId, useMemo } from 'react';

export const enum InputTypes {
  Date = 'date',
  Input = 'input',
  Email = 'email',
  TextArea = 'textArea',
  Select = 'select',
  FieldGroup = 'fieldGroup',
  FileUpload = 'fileUpload',
  Recaptcha = 'recaptcha',
  Password = 'password',
  Checkbox = 'checkbox',
  ZipToCity = 'zipToCity',
  CityFromZip = 'cityFromZip',
  Headline = 'headline',
  PrefixInput = 'prefixInput',
}

type FormGroupProps = ComponentPropsWithoutRef<'div'> & {
  label?: React.ReactNode;
  labelProps?: ComponentPropsWithoutRef<'label'>;
  htmlFor?: string;
  validationError?: string;
  helpText?: string;
  required?: boolean;
  disabled?: boolean;
  inputType?: InputTypes;
  readOnly?: boolean;
  placeholder?: string;
  maxLength?: number;
  name?: string;
};

export function FormGroup({
  label,
  labelProps,
  htmlFor,
  validationError,
  helpText,
  children,
  className,
  required = false,
  disabled = false,
  inputType = InputTypes.FieldGroup,
  ...rest
}: FormGroupProps) {
  // Checkbox should not have a label, but the label should be displayed next to the checkbox
  if (inputType === InputTypes.Checkbox) {
    // Check if children is a valid React element
    const validElement = isValidElement(children);

    // Clone the child element and inject additional children or props
    const enhancedChildren = validElement
      ? cloneElement(children, {}, <>{label}</>, children.props.children)
      : children;

    return (
      <div
        className={cn(
          'group relative',
          {
            'field-invalid': validationError,
          },
          className,
        )}
        {...rest}
      >
        {enhancedChildren}
        {helpText && <div className="m-1 ml-2 flex text-xs text-gray-600">{helpText}</div>}
        {/* Display validation error below the field */}
        {!disabled && validationError && <div className="ml-5 mt-2 pl-2.5 text-xs text-red-500">{validationError}</div>}
      </div>
    );
  }

  return (
    <div className={cn('group relative', { 'field-invalid': validationError }, className)} {...rest}>
      {children}
      {label && (
        <label
          htmlFor={htmlFor}
          {...labelProps}
          className={cn(
            'pointer-events-none absolute left-5 top-4 text-base font-semibold text-gray-500 transition-all',
            'peer-focus:top-2 peer-focus:text-xs',
            'peer-[&:not(:placeholder-shown)]:top-2 peer-[&:not(:placeholder-shown)]:text-xs',
            { 'text-red-500': validationError },
            { 'text-gray-400': disabled },
            labelProps?.className,
            // needed to use specific pixels here
            { 'pl-[54px]': inputType === InputTypes.PrefixInput },
          )}
        >
          {label} {!disabled && required && <span>*</span>}
        </label>
      )}
      {helpText && <div className="m-1 ml-2 flex text-xs text-gray-600">{helpText}</div>}
      {/* Display validation error below the field */}
      {!disabled && validationError && <div className="ml-5 mt-2 text-xs text-red-500">{validationError}</div>}
    </div>
  );
}

FormGroup.Input = withFormGroup(Input, InputTypes.Input);
FormGroup.Date = withFormGroup(Input, InputTypes.Date);
FormGroup.Password = withFormGroup(Input, InputTypes.Password);
FormGroup.Select = withFormGroup(Select, InputTypes.Select);
FormGroup.TextArea = withFormGroup(TextArea, InputTypes.TextArea);
FormGroup.FieldGroup = withFormGroup(FieldGroup, InputTypes.FieldGroup);
FormGroup.FileUpload = withFormGroup(FileUpload, InputTypes.FileUpload);

type FormGroupWithChildProps<P> = P &
  Pick<
    FormGroupProps,
    | 'label'
    | 'labelProps'
    | 'validationError'
    | 'required'
    | 'disabled'
    | 'helpText'
    | 'inputType'
    | 'readOnly'
    | 'placeholder'
    | 'maxLength'
    | 'name'
  > & {
    groupProps?: Omit<FormGroupProps, 'label' | 'labelProps' | 'validationError'>;
  };

export function withFormGroup<Props extends { id?: string }>(Component: ComponentType<Props>, inputType: InputTypes) {
  function Wrapper({
    label,
    labelProps,
    validationError,
    helpText,
    groupProps,
    placeholder,
    maxLength,
    name,
    ...inputProps
  }: FormGroupWithChildProps<Props>) {
    const autoId = useId();
    const inputId = inputProps?.['id'] ?? autoId;
    const required = inputProps?.['required'];
    const disabled = inputProps?.['disabled'];

    const inputPropsWithDefaults = useMemo(
      () => ({
        id: inputId,
        label,
        ...inputProps,
        required,
        disabled,
      }),
      [inputId, label, inputProps, required, disabled],
    );

    const labelPropsWithDefaults = useMemo(
      () => ({
        htmlFor: inputId,
        ...labelProps,
      }),
      [inputId, labelProps],
    );

    return (
      <FormGroup
        {...groupProps}
        label={label}
        labelProps={labelPropsWithDefaults}
        validationError={validationError}
        required={required}
        disabled={disabled}
        inputType={inputType}
        helpText={helpText}
        placeholder={placeholder}
        maxLength={maxLength}
        name={name}
      >
        <Component {...(inputPropsWithDefaults as unknown as Props)} disabled={disabled} {...groupProps} />
      </FormGroup>
    );
  }
  Wrapper.displayName = `FormGroup(${Component.displayName || Component.name})`;

  return Wrapper;
}
