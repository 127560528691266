'use client';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

type CheckboxProps = Omit<ComponentPropsWithoutRef<'input'>, 'size'> & {
  children?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  onCheckedChange?: (checked: boolean) => void;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { children, size = 'md', className, onCheckedChange, onChange, ...rest }: CheckboxProps,
  ref,
) {
  // Get the value from the form context if available
  // This is only for the aria checked state
  const formContext = useFormContext();
  const value = formContext ? formContext?.watch()[rest.name as string] : rest.checked || rest.value;
  return (
    <label className={cn('flex select-none items-start justify-start', className)}>
      <input
        ref={ref}
        className="peer pointer-events-none h-0 w-0 opacity-0"
        onChange={(e) => {
          onCheckedChange?.(e.target.checked);
          onChange?.(e);
        }}
        aria-hidden="true"
        tabIndex={-1}
        type="checkbox"
        {...rest}
      />
      <button
        type="button"
        role="checkbox"
        aria-checked={value ? 'true' : 'false'}
        className={cn(
          'pointer-events-none flex h-5 w-5 min-w-5 items-center justify-center rounded border border-gray-400 bg-white hover:border-gray-500',
          'group-[.field-invalid]:border-red-500',
          'peer-checked:border-blue-500 peer-checked:bg-blue-500',
          'data-[disabled]:border-gray-200 data-[disabled]:bg-gray-100',
          'peer-disabled:pointer-events-auto peer-disabled:cursor-not-allowed peer-disabled:border-gray-200 peer-disabled:bg-gray-100',
          {
            'cursor-not-allowed': rest.disabled,
          },
          {
            'h-4 w-4 min-w-4': size === 'sm',
            'h-5 w-5 min-w-5': size === 'md',
            'h-6 w-6 min-w-6': size === 'lg',
          },
        )}
      >
        {!rest.disabled && (
          <span className="flex items-center justify-center text-white">
            <Icon src="w3-checkmark" size="20" />
          </span>
        )}
      </button>
      <span className="w-full px-2 peer-disabled:cursor-not-allowed peer-disabled:text-gray-400">
        {children}
        {rest.required && <span className="pl-1 text-sm">*</span>}
      </span>
    </label>
  );
});
