import { cn } from '@wla/lib/helpers/cn';
import { VariantProps, cva } from 'class-variance-authority';

type SpinnerVariantProps = VariantProps<typeof spinnerVariants>;

const spinnerVariants = cva('inline-block aspect-square w-6 bg-current loading-mask', {
  variants: {
    size: {
      xs: 'w-4',
      sm: 'w-5',
      md: 'w-6',
      lg: 'w-10',
      xl: 'w-12',
      '2xl': 'w-18',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

type SpinnerProps = SpinnerVariantProps & {
  className?: string;
};

export function Spinner({ className, size }: SpinnerProps) {
  return <div className={cn(spinnerVariants({ size }), className)} />;
}
