import { cn } from '@wla/lib/helpers/cn';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type InputProps = ComponentPropsWithoutRef<'input'>;

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { type = 'text', className, readOnly, ...rest },
  ref,
) {
  return (
    <input
      ref={ref}
      type={type}
      readOnly={readOnly}
      tabIndex={readOnly ? -1 : undefined}
      className={cn(
        // We keep changing the padding back and forth here. It should be like this. Dont change again.
        'peer block h-14 w-full rounded px-5 pb-4 pt-6 text-lg',
        'border border-gray-400 leading-none placeholder-gray-500',
        'disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-600',
        'read-only:pointer-events-none read-only:border-gray-200 read-only:bg-gray-100',
        'hover:border-blue-500',
        'focus:border-blue-500 focus:outline-none focus:ring-4 focus:ring-gray-300',
        'group-[.field-invalid]:border-red-500',
        'invalid:border-red-500',
        'placeholder:font-semibold',
        className,
      )}
      {...rest}
      placeholder=" " // is needed for the label to float
    />
  );
});
