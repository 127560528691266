import { cn } from '@wla/lib/helpers/cn';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type FileUploadProps = Omit<ComponentPropsWithoutRef<'input'>, 'type'> & {
  // added for convenience, so we're compatible with `input` props
  type?: 'file';

  /**
   * Type of file that may be selected by the user
   *
   * A valid case-insensitive filename extension, starting with a period (".") character. For example: .jpg, .pdf, or .doc.
   * A valid MIME type string, with no extensions.
   * The string audio/* meaning "any audio file".
   * The string video/* meaning "any video file".
   * The string image/* meaning "any image file".
   *
   * @default: "*"
   * @see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
   */
  accept?: string;

  /**
   * Allow attaching multiple files?
   * @default: false
   */
  multiple?: boolean;

  /**
   * Which camera to use for capture of image or video data, if the accept attribute indicates that the input should be of one of those types
   * @default: "user"
   * @see: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/capture
   */
  capture?: 'user' | 'environment';
};

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(function FileUpload(
  { className, multiple = false, accept = 'image/png, image/jpeg', capture, disabled, ...rest },
  ref,
) {
  return (
    <div className="relative">
      <input
        ref={ref}
        type="file"
        disabled={disabled}
        accept={accept}
        multiple={multiple}
        capture={capture}
        className={cn(
          'peer file:mr-4 file:cursor-pointer file:rounded file:px-4 file:py-4 file:text-base',
          'file:border file:border-solid file:border-gray-400 file:bg-white file:leading-none',
          'disabled:file:border-gray-200 disabled:file:bg-gray-100 disabled:file:text-gray-400',
          'hover:file:border-blue-500',
          'focus:file:border-blue-500 focus:file:outline-none focus:file:ring-4 focus:file:ring-gray-300',
          'group-[.field-invalid]:file:border-red-500',
          'invalid:file:border-red-500',
          className,
        )}
        {...rest}
      />
    </div>
  );
});
