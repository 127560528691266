import { Icon } from '@wla/components/ui/icon';
import { toast } from 'sonner';

type ToastProps = {
  message: string;
  description?: string;
};

export function errorToast({ message, description }: ToastProps) {
  return toast.error(message, {
    description,
    icon: <Icon src="w3-error" />,
    classNames: {
      toast: 'bg-red-100 border-none',
      title: 'text-gray-800 text-sm md:text-base font-normal',
      description: 'text-gray-800',
    },
  });
}

export function successToast({ message, description }: ToastProps) {
  return toast.success(message, {
    description,
    icon: <Icon src="w3-success" />,
    classNames: {
      toast: 'bg-green-100 border-none',
      title: 'text-gray-800 text-sm md:text-base font-normal',
      description: 'text-gray-800',
    },
  });
}

export function warningToast({ message, description }: ToastProps) {
  return toast.warning(message, {
    description,
    icon: <Icon src="w3-warning" />,
    classNames: {
      toast: 'bg-yellow-100 border-none',
      title: 'text-gray-800 text-sm md:text-base font-normal',
      description: 'text-gray-800',
    },
  });
}

export function infoToast({ message, description }: ToastProps) {
  return toast.info(message, {
    description,
    icon: <Icon src="w3-info" />,
    classNames: {
      toast: 'bg-blue-200 border-none',
      title: 'text-gray-800 text-sm md:text-base font-normal',
      description: 'text-gray-800',
    },
  });
}

export function darkToast({ message, description }: ToastProps) {
  return toast.info(message, {
    description,
    icon: <Icon src="w3-checkmark" className="text-white" />,
    classNames: {
      toast: 'bg-black border-none',
      title: 'text-white text-sm md:text-base font-normal',
      description: 'text-white',
    },
  });
}
