import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

type FormProps = ComponentPropsWithoutRef<'form'> & {
  form: UseFormReturn<any, any, undefined>;
};

export const Form = forwardRef<HTMLFormElement, FormProps>(function Form({ form, children, ...props }, ref) {
  return (
    <FormProvider {...form}>
      <form ref={ref} {...props}>
        {children}
      </form>
    </FormProvider>
  );
});
