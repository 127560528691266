import { cn } from '@wla/lib/helpers/cn';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

type Simplify<T> = { [KeyType in keyof T]: T[KeyType] };

type AnchorProps = Simplify<JSX.IntrinsicElements['a']> & { href: string };
type ButtonProps = Simplify<JSX.IntrinsicElements['button']>;

type LinkElementProps = ((AnchorProps & NextLinkProps) | ButtonProps) & { as?: 'link' | 'button' };

export function Link({ as = 'link', className, ...props }: LinkElementProps) {
  const combinedClassname = cn('cursor-pointer', className);

  if (as === 'button') {
    return (
      <button type="button" {...(props as ButtonProps)} className={cn('text-blue-500 underline', combinedClassname)} />
    );
  }

  return <NextLink {...(props as AnchorProps)} className={combinedClassname} />;
}
