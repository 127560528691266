'use client';

import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { ReactNode } from 'react';

type AccordionProps = {
  title: string;
  children: ReactNode;
  noBorder?: boolean;
  defaultOpen?: boolean;
  titleClassName?: string;
  bodyClassName?: string;
};

export function Accordion({
  title,
  children,
  noBorder,
  defaultOpen = false,
  titleClassName,
  bodyClassName,
}: AccordionProps) {
  return (
    <div
      className={cn('-mt-px border-y border-gray-300 @container/terms-conditions', {
        'border-none': noBorder,
      })}
    >
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <DisclosureButton className="w-full">
              <div className="flex h-full w-full items-center justify-center">
                <p className={cn('my-7 w-full text-left @md/terms-conditions:my-10 sm:text-lg', titleClassName)}>
                  {title}
                </p>
                <Icon src={open ? 'w3-arrow-up' : 'w3-arrow-down'} size="24" />
              </div>
            </DisclosureButton>
            <Transition
              as="div"
              show={open}
              enter="transition-[grid-template-rows] ease-out duration-500"
              enterFrom="grid-rows-[0fr]"
              enterTo="grid-rows-[1fr]"
              leave="transition-[grid-template-rows] ease-in duration-500"
              leaveFrom="grid-rows-[1fr]"
              leaveTo="grid-rows-[0fr]"
              className="grid"
            >
              <DisclosurePanel className={cn('w-full overflow-hidden pb-5 sm:pb-8', bodyClassName)}>
                {children}
              </DisclosurePanel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
