'use client';

import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import { createContext, useContext, useEffect, useState } from 'react';
import { ZodType, z } from 'zod';
import { ConfigurationFeatureFlags } from '@jysk/api-types/drupalApi';
import type { FormSchema } from '../forms/formfields-generator';
import { ConfigSchema, ConfigType, FeatureFlagSchema } from './config-types';

// Create default config from defaults
const emptyConfigFromDefaults = ConfigSchema.default({}).parse({});
const defaultFeatureFlags = FeatureFlagSchema.default({}).parse({}) satisfies ConfigurationFeatureFlags;

export const ConfigContext = createContext<ConfigType>(emptyConfigFromDefaults);

type ConfigProviderProps = {
  children: React.ReactNode;
  config: ConfigType;
  featureFlags: ConfigurationFeatureFlags;
};

export function ConfigProvider({ children, config, featureFlags }: ConfigProviderProps) {
  const parsedFeatureFlags = FeatureFlagSchema.safeParse(featureFlags);
  const useFeatureFlags = parsedFeatureFlags.success ? parsedFeatureFlags.data : defaultFeatureFlags;

  if (!parsedFeatureFlags.success) {
    trackError('Feature flags not parsed correctly', { featureFlags });
  }

  return (
    <ConfigContext.Provider value={{ ...config, featureFlags: useFeatureFlags }}>{children}</ConfigContext.Provider>
  );
}

export function usePublicConfig() {
  const config = useContext(ConfigContext);
  const parsedConfig = ConfigSchema.extend({ featureFlags: FeatureFlagSchema }).safeParse(config);

  if (!parsedConfig.success) {
    trackError('🔴 Config not valid', { parsedConfig });
    throw new Error('Config not valid');
  }

  return parsedConfig.data;
}

type FormData = {
  schema: ZodType;
  fields: FormSchema<z.infer<ZodType>>;
};

export enum SchemaFormType {
  Customer = 'Customer',
  B2BCustomerCreate = 'B2BCustomerCreate',
  B2BCustomerUpdate = 'B2BCustomerUpdate',
  CheckoutOnline = 'CheckoutOnline',
  CheckoutOnlineB2b = 'CheckoutOnlineB2b',
  CheckoutClickCollect = 'CheckoutClickCollect',
  CheckoutClickCollectB2b = 'CheckoutClickCollectB2b',
  CheckoutZipDrawer = 'CheckoutZipDrawer',
}

export const schemaPaths = (defaultLocale: string) => ({
  Customer: `${defaultLocale}/customer-schema.ts`,
  B2BCustomerCreate: `${defaultLocale}/customer-schema.ts`,
  B2BCustomerUpdate: `${defaultLocale}/customer-schema.ts`,
  CheckoutOnline: `${defaultLocale}/checkout-schema.ts`,
  CheckoutOnlineB2b: `${defaultLocale}/checkout-schema.ts`,
  CheckoutClickCollect: `${defaultLocale}/checkout-schema.ts`,
  CheckoutClickCollectB2b: `${defaultLocale}/checkout-schema.ts`,
  CheckoutZipDrawer: `${defaultLocale}/checkout-schema.ts`,
});

const schemaFields = {
  Customer: 'customerAddressFormSchema',
  B2BCustomerCreate: 'defaultCustomerB2bFormSchema',
  B2BCustomerUpdate: 'defaultCustomerB2bUpdateFormSchema',
  CheckoutOnline: 'onlineSchema',
  CheckoutOnlineB2b: 'onlineB2bSchema',
  CheckoutClickCollect: 'clickCollectSchema',
  CheckoutClickCollectB2b: 'clickCollectB2bSchema',
  CheckoutZipDrawer: 'zipCodeDrawerSchema',
};

const fieldMappings = {
  Customer: 'customerAddressFormFields',
  B2BCustomerCreate: 'customerB2bCreateFormFields',
  B2BCustomerUpdate: 'customerB2bUpdateFormFields',
  CheckoutOnline: 'onlineFields',
  CheckoutOnlineB2b: 'onlineB2bFields',
  CheckoutClickCollect: 'clickCollectFields',
  CheckoutClickCollectB2b: 'clickCollectB2bFields',
  CheckoutZipDrawer: 'zipCodeDrawerFields',
};

export function useSchemas(form: SchemaFormType) {
  const { defaultLocale } = usePublicConfig();
  const [data, setData] = useState<FormData | null>(null);

  useEffect(() => {
    async function loadSchema() {
      const path = schemaPaths(defaultLocale)[form];
      if (path) {
        try {
          const schemas = await import(`./schemas/${path}`);
          setData({
            schema: schemas[schemaFields[form]],
            fields: schemas[fieldMappings[form]],
          });
        } catch (error) {
          console.error('Failed to load schema:', { path, error });
          trackError('Failed to load schema', { form, defaultLocale, path, error });
        }
      } else {
        trackError('Schema path not found for form type', { form, defaultLocale });
      }
    }

    loadSchema();
  }, [form, defaultLocale]);

  return {
    schema: data ? data.schema : null,
    fields: data ? data.fields : null,
  };
}
