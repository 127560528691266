import { Icon } from '@wla/components/ui/icon';
import { Spinner } from '@wla/components/ui/spinner';
import { cn } from '@wla/lib/helpers/cn';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type SelectProps = ComponentPropsWithoutRef<'select'> & {
  arrowDirection?: 'down' | 'right';
  loading?: boolean;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(
  { className, arrowDirection = 'right', loading, ...rest }: SelectProps,
  ref,
) {
  return (
    <div className="group relative inline-block w-full">
      <select
        ref={ref}
        className={cn(
          'peer block w-full truncate rounded bg-none px-5 py-4 pr-9 text-base font-semibold',
          'border border-gray-400 leading-normal placeholder-gray-500',
          'disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400',
          'focus:border-blue-500 focus:outline-none focus:ring-4 focus:ring-gray-300',
          'form-invalid:border-danger form-invalid:focus:border-gray-600 enabled:cursor-pointer',
          className,
        )}
        {...rest}
      />
      <div
        className={cn('pointer-events-none absolute inset-y-0 right-5 flex items-center', {
          'group-focus:rotate-90': arrowDirection === 'right',
        })}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Icon
            src={arrowDirection === 'down' ? 'w3-arrow-down' : 'w3-arrow-right'}
            size="24"
            className="peer-disabled:disabled"
          />
        )}
      </div>
    </div>
  );
});
