import { cn } from '@wla/lib/helpers/cn';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

type FieldGroupProps = ComponentPropsWithoutRef<'fieldset'> & {
  children?: ReactNode;
  label?: string;
  id?: string;
};

export const FieldGroup = ({ children, disabled, className = 'flex-col' }: FieldGroupProps) => {
  return (
    <fieldset
      className={cn(
        'peer flex border border-gray-200 p-2 pt-3',
        'form-invalid:border-danger form-invalid:focus:border-gray-600',
        'disabled:text-gray-600',
        { 'pointer-events-none cursor-default': disabled },
      )}
      disabled={disabled}
    >
      <div className={cn('flex gap-2 text-gray-800', { '!text-gray-600': disabled }, className)}>{children}</div>
    </fieldset>
  );
};
