import { ValidateEmailResponse } from '@wla/app/api/validate-email/route';
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from './input';

type EmailInputInputProps = ComponentPropsWithoutRef<'input'>;

export const EmailInput = forwardRef<HTMLInputElement, EmailInputInputProps>(function EmailInput(
  { onBlur, ...rest },
  ref,
) {
  const abortControllerRef = useRef<AbortController | null>(null);
  const formContext = useFormContext();

  return (
    <Input
      type="email"
      inputMode="email"
      onBlur={async (e) => {
        const name = rest.name || 'email';
        const email = e.target.value;

        // Abort the previous request if it exists
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }

        if (email) {
          // Create a new AbortController
          abortControllerRef.current = new AbortController();
          const { signal } = abortControllerRef.current;

          try {
            const resp = await fetch('/api/validate-email', {
              method: 'POST',
              body: JSON.stringify({ email }),
              signal,
            });
            const data = (await resp.json()) as ValidateEmailResponse;

            if (data.success) {
              formContext.clearErrors(name);
              formContext.trigger(name);
              onBlur?.(e);
            } else {
              formContext.setError(name, {
                type: 'validate',
                message: 'forms.errors.not-valid.email-domain-not-valid',
              });
            }
          } catch (error) {
            if ((error as Error).name !== 'AbortError') {
              formContext.setError(name, { type: 'validate', message: 'forms.erros.not-valid.email-not-valid' });
            }
          }
        } else {
          formContext.setError(name, { type: 'validate', message: 'forms.errors.something-went-wrong' });
          onBlur?.(e);
        }
      }}
      {...rest}
      ref={ref}
    />
  );
});
