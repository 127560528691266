import { cn } from '@wla/lib/helpers/cn';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type resizeProp = 'none' | 'vertical' | 'horizontal' | 'both';
type TextAreaProps = ComponentPropsWithoutRef<'textarea'> & { resize?: resizeProp } & {
  counter?: number;
  maxLength?: number;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(function Input(
  { className, resize, disabled = false, counter = 0, maxLength, ...rest },
  ref,
) {
  return (
    <>
      <textarea
        ref={ref}
        disabled={disabled}
        style={{ resize }}
        className={cn(
          'peer block h-14 w-full rounded px-5 pb-4 pt-6 text-lg',
          'border border-gray-400 leading-none placeholder-gray-500',
          'disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400',
          'read-only:pointer-events-none read-only:border-gray-200 read-only:bg-gray-100',
          'hover:border-blue-500',
          'focus:border-blue-500 focus:outline-none focus:ring-4 focus:ring-gray-300',
          'group-[.field-invalid]:border-red-500',
          className,
        )}
        {...rest}
        placeholder=" " // is needed for the label to float
      />
      {maxLength && (
        <span className="float-right inline-flex pt-2.5 text-sm text-gray-500">{`${counter}/${maxLength}`}</span>
      )}
    </>
  );
});
